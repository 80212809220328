<template>
    <div class="builder-sidebar-right">
        <div class="builder-sidebar-right__scroll">
            <div v-if="isSidebarRightShow" class="builder-sidebar-right__container">
                <div class="builder-sidebar-right__heading">
                    <span>{{ sidebarTitle | capitalize }}</span>
                </div>
                <div v-if="current" class="builder-sidebar-right__content">
                    <OptionListPages v-if="currentType === 'page'" :key="current.id" />
                    <OptionListSections v-if="currentType === 'section'" :key="current.id" />
                    <OptionListSectionItems v-if="currentType === 'sectionItem'" :key="current.id" />
                    <OptionListSettings v-if="currentType === 'settings'" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'SideBarRight',
    components: {
        OptionListPages: () => import('@/components/builder/optionList/page'),
        OptionListSections: () => import('@/components/builder/optionList/sections'),
        OptionListSectionItems: () => import('@/components/builder/optionList/section-item.vue'),
        OptionListSettings: () => import('@/components/builder/optionList/settings'),
    },
    data() {
        return {
            menu: {
                id: null,
                position: 1,
                type: 'menu',
                component: 'MenuEditor',
                value: 'main',
                options: {
                    depth: 3,
                    labelTrans: 'settings.header.menu',
                },
            },
        };
    },
    computed: {
        ...mapState('builder', {
            currentPage: 'currentPage',
            settings: 'settings',
            activeItem: 'activeItem',
        }),
        ...mapGetters('builder', {
            isSidebarRightShow: 'isSidebarRightShow',
            current: 'getSidebarRightCurrent',
            currentType: 'getSidebarRightCurrentType',
        }),
        sidebarTitle() {
            if (this.currentType === 'settings') return this.$t('builder.sidebarRigth.websiteSettings');
            if (this.currentType === 'block') return this.$t('builder.sidebarRigth.title');
            if (this.currentType === 'page') return this.$t('builder.sidebarRigth.pageSettings');
            if (this.current?.type) {
                const title =
                    this.current.options?.heading?.title?.value || this.current.options?.product?.value?.title;
                if (title) return title;
                const type = this.current.type;
                return this.$t('builder.sections.' + type);
            }
            return this.current;
        },
    },
    methods: {
        ...mapActions('builder', {
            updateActiveItem: 'updateActiveItem',
        }),
        openSettingsPanel(tab) {
            const payload = {
                type: tab === 'page' ? 'page' : 'settings',
                data: tab === 'page' ? cloneDeep(this.currentPage) : cloneDeep(this.settings),
            };
            this.updateActiveItem(payload);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
.builder-sidebar-right {
    position: relative;
    height: 100%;

    &__container {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        height: 100%;
    }

    &__scroll {
        width: 100%;
        height: 100%;
        overflow: auto;
        flex: auto;
        overflow-x: hidden;
    }

    &__heading {
        display: flex;
        align-items: center;
        padding: $spacer * 6.5 $spacer * 5;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        span {
            font-weight: 500;
            font-size: 19px !important;
        }
    }

    &__options-list {
        flex: auto;
        margin-top: $spacer * 4;
    }

    &__content {
        flex: 1;
        padding: $spacer * 4 $spacer * 4 $spacer * 2;
    }
}
.builder-options-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    &__inner {
        flex: auto;
        overflow: auto;
        background: #fff;
    }

    .list-item__content {
        overflow: visible;
    }

    &__btn-remove {
        width: 100%;
        padding: $spacer * 3 $spacer * 3 $spacer * 4;
    }
}
.builder-sidebar-right-nav {
    display: flex;
    padding: 10px;
    &__btn {
        display: flex;
        justify-content: center;
        width: 50%;
        color: var(--v-on-surface-medium-base);
        line-height: 48px;
        border-bottom: 2px solid transparent;

        &:hover,
        &.-active {
            color: var(--v-primary-base);
            border-bottom: 2px solid var(--v-primary-base);
        }

        &:disabled {
            cursor: not-allowed;
            color: var(--v-on-surface-medium-base);
            border-bottom: 2px solid transparent;
        }
    }
}
</style>
